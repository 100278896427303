<template>
    <section>
        <Transition  name="fade" appear >
            <div class="container-fluid p-0 m-0 slider" >
                <div class="carousel slide carousel-fade border-bottom"  data-aos="fade-left" id="carousel" data-bs-ride="carousel" data-bs-pause="false" data-bs-touch="false">
                    <div class="carousel-inner">
                        <div class="carousel-item p-0" 
                                data-bs-interval="5000" :class="[slide.id == 24 ? 'active' : '']"  v-for="(slide,z) in slides" :key="z" >
                            <div :style="{ 'background-image': 'linear-gradient( rgba(0,0,0,.3), rgba(0,0,0,.3) ), url(' + $url+'/'+slide.image + ')'}" style="background-size:cover; height:100%" >
                                <div class="slide-text">
                                    <h1 class="p-3 text-nowrap">Marina SaMont</h1>
                                    <h2 style="color:white">fine artist</h2>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </Transition>
       
        <div class="container statement position-relative vl-box"  data-aos="fade-up">
            <div class="row">
                <div class="col-12 " style="height:7rem">
                    <div class="vl"></div>
                </div>
                <div  class="col-md-8 col-12 offset-md-2 text-center ps-5 pe-5" >
                    <p v-if="$lang == 'es'" class="italic" >"Desde el rincón más oscuro de la imaginación, de lo ordinario a lo grotesco con un 
                        enfoque al temor psicológico, cada obra invita a la interpretación personal para encontrar la 
                        relación de nuestros recuerdos con los mundos que presento en cada obra."
                    </p>
                    <p v-else class="italic">"From the darkest corner of the imagination, from the ordinary to the grotesque with an 
                        approach to psychological fear, each work invites personal interpretation to find the 
                        relationship of our memories with the worlds that I present in each work of art. "
                    </p>
                    <p class="text-end italic signature" >Marina SaMont</p>
                </div>
            </div>
        </div>
      
        <div class="container " style="margin-top: 9rem !important;"  data-aos="fade-up">
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="title-subtitle text-uppercase" v-if="$lang == 'es'">portafolio</h1>
                    <h1 class="title-subtitle text-uppercase" v-else>portfolio</h1>
                </div>
            </div>
        </div>
    
        <ArtworksComponent  data-aos="fade-up" />
    </section>
</template>
<script>
import ArtworksComponent from './Artworks.vue'
import axios from 'axios'
export default {
    name: 'HomeComponent',
    components:{ArtworksComponent},
    data(){
        return{
            slides:[],
            events:[]
        }
    },
    created(){
        this.fetchSlides()
        this.getEvents()
    },
   
    methods: {
        fetchSlides() {
            axios.get(this.$url+'/sliders',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.slides = res.data.sort((a, b) => b.id - a.id)
            }).catch((err)=>{
                console.log(err)
            })
        },
         getEvents(){
             axios.get(this.$url+'/events-h',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.events = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
    }
}
</script>
                                                                                                                        