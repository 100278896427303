<template>
    <section class="container shop">
        <div class="row "><div class="col-12"></div></div>
        
        <Transition  name="fade" appear >
            <div class="row pb-5" v-if="originals.length > 0"  >
                <div class="text-center col-12 title-store" >
                    <div >
                        <h1 v-if="$lang == 'es'" class="shop-title title-subtitle">Originales</h1>
                        <h1 v-else class="shop-title title-subtitle">Originals</h1>
                        <h3 v-if="$lang == 'es'" class="subtitle">permítete vivir en la elusividad</h3>
                        <h3 v-else class="subtitle " style="font-size:1.5;color:white">allow yourself to live in elusiveness</h3>
                    </div>
                </div>
            </div>  
        </Transition>
        <div class="row artworklist" v-if="originals.length > 0">
            <div class="col-6 col-md-3 pb-5" v-for="(item,x) in originals" :key="x" data-aos="fade-up" >
                <router-link v-if="item.link" :to="'/shop/'+item.slug" style="text-decoration: none;">
                    <div class="cube" :style="{ 'background-image': 'url(' + $url+'/'+item.featured + ')' }"></div>
                    <h5 class="subtitle pb-0 pt-0 text-center">{{ item.title }}</h5>
                </router-link>
                <a v-else-if="item.external_link" :href="item.external_link" target="_blank" style="text-decoration: none;">
                    <div class="cube" :style="{ 'background-image': 'url(' + $url+'/'+item.featured + ')' }"></div>
                    <h5 class="subtitle text-center pb-0 pt-0">{{ item.title }}</h5>
                </a>
            </div>
        </div>
        <Transition  name="fade" appear >    
            <div class="row pb-5" v-if="sketches.length > 0"  data-aos="fade-up" >
                <div class="col-md-6 col-12 p-0">
                    <img src="/images/shop-bocetos.jpeg" class="img-fluid" alt="COA"/>
                </div>

                <div class="col-md-6 col-12 p-0 black-box-coa" >
                    <div v-if="$lang == 'es'" class="inner-box">
                        <h3 class="text-center">Bocetos & Dibujos</h3>
                        <p class="cursive">Los bocetos son el alma de una pintura, el primer paso, el esqueleto. Cada trazo abre un mundo de posibilidades.</p>
                    </div>
                    <div v-else class="inner-box">
                        <h3 class="text-center">Sketches & Drawings</h3>
                        <p class="cursive">Sketches are the soul of a painting, the first step, the skeleton. Every stroke unfolds a realm of possibilities.</p>
                    </div>
                </div>
            </div>
        </Transition>
            <div class="row artworklist"  v-if="sketches.length > 0">
                <div class="col-6 col-md-3 pb-5" v-for="(item,x) in sketches" :key="x"   data-aos="fade-up">
                    <router-link v-if="item.link" :to="'/shop/'+item.slug"  style="text-decoration: none;">
                        <div class="cube" :style="{ 'background-image': 'url(' + $url+'/'+item.featured + ')' }"></div>
                        <h5 class="subtitle pb-0 pt-0 text-center">{{ item.title }}</h5>
                    
                    </router-link>
                    <a v-else-if="item.external_link" :href="item.external_link" target="_blank" style="text-decoration: none;">
                        <div class="cube" :style="{ 'background-image': 'url(' + $url+'/'+item.featured + ')' }"></div>
                        <h5 class="subtitle text-center pb-0 pt-0">{{ item.title }}</h5>
                    </a>
                </div>
            </div>

            <div class="row " v-show="false"   data-aos="fade-up">
                <div class="text-center col-12" v-if="prints.length > 0">
                    <p v-if="$lang == 'es'">Impresiones</p>
                    <p v-else>Prints</p>
                </div>
            </div>
            <div class="row artworklist" v-show="false" style="padding:0 6rem" v-if="prints.length > 0">
                <div class="col-6 col-md-3 pb-5" v-for="(item,x) in prints" :key="x"   data-aos="fade-up">
                    <router-link v-if="item.link" :to="'/shop/'+item.slug"  style="text-decoration: none;">
                        <div class="cube" :style="{ 'background-image': 'url(' + $url+'/'+item.featured + ')' }"></div>
                        <h5 class="subtitle pb-0 pt-0 text-center">{{ item.title }}</h5>
                    
                    </router-link>
                    <a v-else-if="item.external_link" :href="item.external_link" target="_blank" style="text-decoration: none;">
                        <div class="cube" :style="{ 'background-image': 'url(' + $url+'/'+item.featured + ')' }"></div>
                        <h5 class="subtitle text-center pb-0 pt-0">{{ item.title }}</h5>
                    </a>
                </div>
            </div>

            <div class="row " data-aos="fade-up">
                <div class="col-md-6 col-12 p-0 black-box-coa" >

                    <div v-if="$lang == 'es'" class="inner-box">
                        <h3 class="text-center">Certificado de Autenticidad</h3>
                        <p class="cursive">Todas las obras incluyen un Certficado de Autenticidad con sellado y firmado por Marina SaMont</p>
                    </div>
                    <div v-else class="inner-box">
                        <h3 class="text-center">Certificate of Authenticity</h3>
                        <p class="cursive">Every Artwork includes a Certificate of Authenticity dated and signed by Marina SaMont</p>
                    </div>

                </div>
                <div class="col-md-6 col-12 p-0">
                    <img src="/images/shop-coa.jpg" class="img-fluid" alt="COA"/>
                </div>
            </div>
        </Transition>
    </section>
</template>
<script>
import axios from 'axios'
               
export default {
    name: 'FigurativeComponent',
    data(){
        return{
            originals:[],
            sketches:[],
            prints:[]
        }
    },
    created(){
        this.getArtworks()
    },
    methods:{
        async getArtworks(){
            await axios.get(this.$url+'/shop',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.originals  = res.data['originals']
                this.sketches   = res.data['sketches']
                this.prints     = res.data['prints']
            }).catch((err)=>{
                console.log(err)
            })
        },
        
    }
    
}
</script>