<template>
    <section class="container-fluid top">
        <div class="row more-top" data-aos="fade-up">
            <div class="col-12  ">
                <div class="row d-none d-md-flex">
                    <div class="col-4" >
                        <div v-for="(item,x) in col1" :key="x" class="pb-4 p-1">
                            <router-link :to="'/sketch/'+item.slug" >
                                <img :src="$url+'/'+item.featured" class="img-fluid cursor-pointer" :alt="item.title"/>
                                <h3 v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                                <p v-show="false" style="white-space:pre-line">
                                    {{ item.year }}<br>
                                    {{ $lang == 'es' ? item.media_es : item.media_en }}<br>
                                    {{ item.dimensions }}
                                </p>
                            </router-link>
                        </div>
                    </div>

                    <div class="col-4">
                        <div v-for="(item,y) in col2" :key="y" class="pb-4 p-1">
                            <router-link :to="'/sketch/'+item.slug" >
                                <img  :src="$url+'/'+item.featured" class="img-fluid cursor-pointer" :alt="item.title"/>
                                <h3 v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                                <p v-show="false"  style="white-space:pre-line">
                                    {{ item.year }}<br>
                                    {{ $lang == 'es' ? item.media_es : item.media_en }}<br>
                                    {{ item.dimensions }}
                                </p>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-4">
                        <div v-for="(item,y) in col3" :key="y" class="pb-4 p-1">
                            <router-link :to="'/sketch/'+item.slug">
                                <img :src="$url+'/'+item.featured" class="img-fluid cursor-pointer" :alt="item.title"/>
                                <h3 v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                                <p  v-show="false" style="white-space:pre-line">
                                    {{ item.year }}<br>
                                    {{ $lang == 'es' ? item.media_es : item.media_en }}<br>
                                    {{ item.dimensions }}
                                </p>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="row d-md-none">
                    <div class="col-6 pb-5" v-for="(item,x) in all" :key="x" >
                        <router-link :to="'/sketch/'+item.slug">
                            <img  :src="$url+'/'+item.featured" class="img-fluid" :alt="item.title"/>
                            <h3  v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                            <p  v-show="false" style="white-space:pre-line">
                                {{ item.year }}<br>
                                {{ $lang == 'es' ? item.media_es : item.media_en }}<br>
                                {{ item.dimensions }}
                            </p>
                        </router-link>
                    </div>
                </div>
            </div>
        
        </div>

    </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'SketchesComponent',
    
    data(){
        return{
            col1:{},
            col2:{},
            col3:{},
            all:{},
            images:[],
           
        }
    },
    created(){
        this.getArtworks()
    },
   
    methods:{
        async getArtworks(){
            await axios.get(this.$url+'/sketches',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.col1 = res.data.col1
                this.col2 = res.data.col2
                this.col3 = res.data.col3
                this.all = res.data.all
            }).catch((err)=>{
                console.log(err)
            })
        },
        show(image) {
            this.$viewerApi({
            options: {
                    toolbar: false,
                    title:false,
                    loading:false,
                    backdrop:true,
                    button:true,
                    focus:true,
                    fullscreen:true,
                    loop:false,
                    keyboard:false,
                    movable:false,
                    navbar:false,
                    rotatable:false,
                    scalable:true,
                    slideOnTouch:false,
                    toggleOnDblclick:true,
                    tooltip:false,
                    transition:false,
                    zoomable:false,
                    zoomOnTouch:false,
                    zoomOnWheel:false,
                }, 
            images: [this.$url+'/'+image]})
            console.log(this.images)
        },
    },
    
}
</script>