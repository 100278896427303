<template>
   
</template>
<script>
import axios from 'axios'
               
export default {
    created(){
        this.getArtworks()
    },
    
    methods:{
         getArtworks(){
            axios.get(this.$url+'/saatchi/'+this.$route.params.slug,{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                window.location.href = res.data.external_link
            }).catch((err)=>{
                console.log(err)
            })

        }
    }
}
</script>