<template>
    <section style="background-color:black;color:white" class="mt-5">
        <div class="row">
            <div class="col-md-6 col-12">
                <img src="/images/20240427_174835(1).jpg" alt="subscribe" class="img-fluid" />
            </div>
            <div class="col-md-6 col-12">
                <div class="container-fluid p-5">
                    <h4 class="title text-light" v-if="$lang == 'en'">Would you like to know more about my inspirations?</h4>
                    <h4 class="title text-light" v-else>Te gustaría conocer mas sobre mi inspiracion</h4>
                    <p class="text-light" v-if="$lang == 'en'">Step into a world of possibilities in the newsletter that comes straight from my studio to your inbox..</p>
                    <p class="text-light" v-else>Entra en un mundo de posibilidades con las noticias que llegas directamente desde mi estudio a tu email..</p>
                   
                    <form @submit.prevent="sendForm()" class="pt-5" v-if="!showSubscribedNote">
                        <ul class="list-unstyled contact-form">
                            <li>
                                <input type="text" class="form-control" v-model="subscribe.name" required :placeholder=" $lang == 'es' ? 'Nombre':'Name' ">
                            </li>
                            <li>
                                <input type="email" class="form-control" v-model="subscribe.email" required placeholder="Email *">
                            </li>
                            
                            <li class="text-center">
                                <button type="submit" class="btn btn-red btn-block">{{$lang == 'es' ? 'SUSCRIBIRME':'SUBSCRIBE' }}</button>
                            </li>
                        </ul>
                    </form>
                    <div class="alert alert-success" v-else >
                                <p v-if="$lang == 'en'">Success! Now check your email to confirm your subscription.</p>
                                <p v-else>¡Éxito! Ahora revisa tu email para confirmar tu suscripción.</p>
                            </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script >
import axios from 'axios'

export default {
    name: 'SubscriptionComponent',
   
    data() {
      return {
        subscribe:{},
        showSubscribedNote:false
      }
    },
    methods:{
        sendForm(){
            if(this.subscribe.email=='' || this.subscribe.name==''){
                return
            }
            axios.post(this.$url+'/subscribe',{  accion: 'subscribe', name: this.subscribe.name, email: this.subscribe.email,lang:this.$lang  },{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((data)=>{
                if(data){
                    this.showSubscribedNote = true
                }
            }).catch((err)=>{
                console.log(err)
            })
        }
    }
   
}
</script>