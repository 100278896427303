<template>
    <section class="container">
        <Transition  name="fade" appear >
            <div class="row top">
                <div class="col-12 card ">
                    <div class="row d-none d-md-flex">
                        <div class="col-6" >
                            <div v-for="(event,x) in event" :key="x" class="pb-4 p-1" data-aos="fade-up">
                                <router-link :to="'/event/'+event.slug"  >
                                    <img  :src="$url+'/'+event.featured" class="img-fluid cursor-pointer" :alt="event.title"/>
                                    <h3 v-show="false" class="subtitle pb-0 pt-0">{{ event.title }}</h3>
                                    <p v-show="false" style="white-space:pre-line">
                                        {{ event.year }}<br>
                                        {{ $lang == 'es' ? event.media_es : event.media_en }}<br>
                                        {{ event.dimensions }}
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="row d-md-none">
                        <div class="col-12 pb-3" v-for="(event,x) in event" :key="x" data-aos="fade-up">
                            <router-link :to="'/event/'+event.slug"  >
                                <img  :src="$url+'/'+event.featured" class="img-fluid" :alt="event.title"/>
                                <h3  v-show="false" class="subtitle pb-0 pt-0">{{ event.title }}</h3>
                                <p  v-show="false" style="white-space:pre-line">
                                    {{ event.year }}<br>
                                    {{ $lang == 'es' ? event.media_es : event.media_en }}<br>
                                    {{ event.dimensions }}
                                </p>
                            </router-link>
                        </div>
                    </div>
                </div>
                
            </div>
        </Transition>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'EventComponent',
    
    data(){
        return{
            event:{},
            artworks:[]
        }
    },
    created(){
        this.getEvent()
    },
  
    methods:{
        async getEvent(){
            await axios.get(this.$url+'/show/'+this.$route.params.slug,{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.event = res.data.event
                this.artworks = res.data.artworks
            }).catch((err)=>{
                console.log(err)
            })
        },
    },
    
}
</script>