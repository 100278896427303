<template>
    <section class="container">
       
            <div class="row top" >
                <div class="col-12 card " data-aos="fade-up">
                    <div class="row ">
                        <div class="col-12" >
                            <div v-for="(event,x) in events" :key="x" class="pb-4 p-1 text-center" data-aos="fade-up">  
                                <router-link v-show="false" :to="'/events/'+event.slug" >
                                    <div class="row" > 
                                        <div class="col-8">
                                            <img :alt="event.slug" class="img-fluid" style="background-size:cover" :src="$url+'/'+event.featured_image" />
                                        </div>
                                        <div class="col-4">
                                            <div class="card" v-if="$lang == 'es'">{{event.description_es}}</div>
                                            <div class="card" v-else>{{event.description_en}}</div>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="row">
                                    <div class="col-12">
                                        <img :alt="event.slug" class="img-fluid" style="background-size:cover" :src="$url+'/'+event.featured_image" />
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'EventsComponent',
    
    data(){
        return{
            events:{}
        }
    },
    created(){
        this.getEvents()
    },
  
    methods:{
        async getEvents(){
            await axios.get(this.$url+'/events',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                console.log(res.data)
                this.events = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        
    },
    
}
</script>