import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/Home.vue'
import ItemView from '@/views/Item.vue'
import ArtworksView from '@/views/Artworks.vue'
import SketchesView from '@/views/Sketches.vue'
import ArtistView from '@/views/Artist.vue'
import EventsView from '@/views/Events.vue'
import ShowView from '@/views/Show.vue'
import SaatchiView from '@/views/SaatchiView.vue'
import ShopView from '@/views/Shop.vue'
import SalesView from '@/views/SalesContact.vue'
import ContactView from '@/views/Contact.vue'
import PolicyView from '@/views/PrivacyPolicy.vue'
import TermsView from '@/views/TermsCondition.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/',name: 'home',component: HomeView},
  {path: '/events',name: 'events',component: EventsView},
  {path: '/events/:slug',name: 'event',component: ShowView},
  {path: '/paintings',name: 'paintings',component: ArtworksView},
  {path: '/artwork/:slug',name: 'painting',component: ItemView},
  {path: '/sketches',name: 'sketches',component: SketchesView},
  {path: '/sketch/:slug',name: 'sketch',component: ItemView},
  {path: '/artist',name: 'artist',component: ArtistView},
  {path: '/shop',name: 'shop',component: ShopView},
  {path: '/shop/:slug',name: 'item',component: SalesView},
  {path: '/saatchi/:slug',name: 'saatchi',component: SaatchiView},
  {path: '/contact',name: 'contact',component: ContactView},
  {path: '/privacy-policy',name: 'policy',component: PolicyView},
  {path: '/terms-and-conditions',name: 'terms',component: TermsView}
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0, 
      behavior: 'smooth',
    }
  }
})

export default router
